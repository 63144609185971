import { MAC_ADDRESS_REGEX } from "src/craas/utils/constants"
import { AddEditLocationConditionFormType } from "src/pages/Conditions/AddEditLocationCondition"
import { parseTimezone } from "src/pages/Conditions/TimeConditions/TimeConditions.utils"
import { AddEditPolicyType } from "src/pages/Policies/AddEditPolicy/AddEditPolicy.service"
import { FormFieldType } from "src/pages/Services/PublicSaas/AddEditPublicSaas/AddEditPublicSaas"
import { mapHostIcon } from "src/services/api/swrHooks/useHosts"
import { IDPValueType } from "src/services/api/swrHooks/useIDPSettings"
import {
  ALPHANUMERIC_SPACE_HYPHEN_REGEX,
  ALPHA_NUMERIC_REGEX,
  ApplicationTypes,
  AWS_ACCOUNT_ID_REGEX,
  AZURE_SUBSCRIPTION_ID_REGEX,
  CIDR_NOTATION_REGEX,
  DOMAIN_NAME_REGEX,
  EMAIL_REGEX,
  GCP_CONNECTOR_NAME_REGEX,
  IPV6_CIDR_NOTATION_REGEX,
  IPV6_REGEX,
  IP_ADDRESS_OR_HOSTNAME_REGEX,
  IP_ADDRESS_REGEX,
  isInvalidIPAddress,
  NETWORK_PORT_NUMERIC_REGEX,
  NETWORK_PORT_REGEX,
  NETWORK_PORT_REGEX_WITHOUT_RANGE,
  PASSWORD_REGEX,
  PORT_NOT_REQUIRED_PROTOCOLS,
  PORT_REGEX,
  SAAS_APP_URL_REGEX,
  SPACE_CHECK_REGEX,
  WHITE_SPACE_CHECK,
  XML_FORMAT_REGEX,
} from "src/utils/constants"
import * as yup from "yup"
import { AnyObject } from "yup/lib/object"
import { isIntegerWithNoLeadingZero } from ".."

export const handlePortsValidation: yup.TestFunction<string | undefined, AnyObject> = (
  value = "",
  { createError, path, parent },
) => {
  const { protocol } = parent
  const isTcpOrUdp = ["UDP or TCP", "UDP", "TCP"].includes(protocol)
  if (!NETWORK_PORT_NUMERIC_REGEX.test(value)) {
    // Allows only numbers, commas, or hyphens
    return createError({
      message: isTcpOrUdp
        ? "Port numbers should only contain numbers, or commas."
        : "Port numbers should only contain numbers, commas, or hyphens.",
      path,
    })
  }

  if (!isTcpOrUdp && !NETWORK_PORT_REGEX.test(value)) {
    // Allows only number(s), range(s), or their comma separated combination(s)
    return createError({
      message: "Incorrect Port Format",
      path,
    })
  }
  if (isTcpOrUdp && !NETWORK_PORT_REGEX_WITHOUT_RANGE.test(value)) {
    return createError({
      message: "Incorrect Port Format",
      path,
    })
  }

  const ports = new Set() // Contains all ports
  const ranges: Set<string> = new Set() // Contains ranges only

  const portArray = value.split(",") // Process individual ports separated by commas

  for (const port of portArray) {
    if (port.includes("-")) {
      // Process a port range

      const [start, end] = port.split("-")
      const startNumber = Number(start)
      const endNumber = Number(end)

      if (!isIntegerWithNoLeadingZero(start)) {
        return createError({
          message: `The port range start number ${start} has a leading zero`,
          path,
        })
      } else if (!isIntegerWithNoLeadingZero(end)) {
        return createError({
          message: `The port range end number ${end} has a leading zero`,
          path,
        })
      } else if (startNumber >= endNumber) {
        return createError({
          message: "Invalid range",
          path,
        })
      } else if (startNumber < 0) {
        return createError({
          message: "The port range start number must not be smaller than 0",
          path,
        })
      } else if (endNumber > 65535) {
        return createError({
          message: "The port range end number must not be greater than 65535",
          path,
        })
      } else if (ports.has(port)) {
        return createError({
          message: `The port range ${port} already exists`,
          path,
        })
      } else if (
        Array.from({ length: endNumber - startNumber + 1 }, (_, idx) => startNumber + idx).some((val) => ports.has(val))
      ) {
        // Checks if any port in the range is already present in the set
        // Array.from({ length: endNumber - startNumber + 1 }, (_, idx) => startNumber + idx) makes an array of all the numbers in the range
        return createError({
          message: `The port range ${port} contains existing port(s)`,
          path,
        })
      } else {
        for (const range of ranges) {
          const [start, end] = range.split("-")
          const rangeStartNumber = Number(start)
          const rangeEndNumber = Number(end)

          if (
            (startNumber >= rangeStartNumber && startNumber <= rangeEndNumber) ||
            (endNumber >= rangeStartNumber && endNumber <= rangeEndNumber) ||
            (rangeStartNumber >= startNumber && rangeStartNumber <= endNumber) ||
            (rangeEndNumber >= startNumber && rangeEndNumber <= endNumber)
          ) {
            return createError({
              message: `The port range ${port} overlaps with existing port range ${range}`,
              path,
            })
          }
        }
      }

      ports.add(port)
      ranges.add(port)
    } else {
      const portNumber = Number(port)

      if (!isIntegerWithNoLeadingZero(port)) {
        return createError({
          message: `The port number ${port} has a leading zero`,
          path,
        })
      } else if (portNumber < 0) {
        return createError({
          message: "The port number must not be smaller than 0",
          path,
        })
      } else if (portNumber > 65535) {
        return createError({
          message: "The port number must not be greater than 65535",
          path,
        })
      } else if (ports.has(portNumber)) {
        return createError({
          message: `The port number ${portNumber} already exists`,
          path,
        })
      } else {
        for (const range of ranges) {
          const [start, end] = range.split("-")
          const startNumber = Number(start)
          const endNumber = Number(end)

          if (portNumber >= startNumber && portNumber <= endNumber) {
            return createError({
              message: `The port number ${portNumber} overlaps with existing port range ${range}`,
              path,
            })
          }
        }
      }

      ports.add(portNumber)
    }
  }

  return true
}

export const emailValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .max(320, "Email must not be longer than 320 characters")
    .matches(EMAIL_REGEX, "The email you have entered is invalid. Try again.")
    .email("The email you have entered is invalid. Try again."),
})

export const forgetPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .max(320, "Email must not be longer than 320 characters")
    .matches(EMAIL_REGEX, "Email is Invalid")
    .email("Email is Invalid"),
})

export const configureHostValidation = yup.object().shape({
  connectorAlias: yup
    .string()
    .required("Connector Name is Required")
    .min(3, "Connector Name must not be smaller than 3 characters")
    .max(31, "Connector Name must not be longer than 31 characters")
    .matches(ALPHA_NUMERIC_REGEX, "Invalid characters. Only alphanumeric and hyphens are allowed"),
  site: yup.object().shape({ id: yup.string(), name: yup.string() }).nullable().required("Associate Site is required"),
  mtu: yup
    .string()
    .required("MTU is required")
    .matches(/^[0-9]+$/, "MTU must be a valid integer between 1200-1400 bytes")
    .test("range-check", "Allowed range for MTU is 1200-1400 bytes", (val: any) => {
      return parseInt(val) >= 1200 && parseInt(val) <= 1400
    }),
})

export const addEditUserGroupValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(SPACE_CHECK_REGEX, "Name cannot contain only space characters")
    .min(3, "Name must not be smaller than 3 characters")
    .max(31, "Name must not be longer than 31 characters"),
  description: yup.string().nullable().max(200, "Description must not be longer than 200 characters"),
})

export const addEditDeviceGroupValidation = yup.object().shape({
  name: yup
    .string()
    .required("Device Group Name is Required")
    .matches(SPACE_CHECK_REGEX, "Device Group Name cannot contain only space characters")
    .min(3, "Device Group Name must not be smaller than 3 characters")
    .max(31, "Device Group Name must not be longer than 31 characters"),
  description: yup.string().nullable().max(200, "Description must not be longer than 200 characters"),
})

export const addExistingDeviceValidation = yup.object().shape({
  devices: yup
    .array()
    .of(yup.object().shape({}))
    .required("Devices are required")
    .min(1, "At least one device is required"),
})

const getCyclicDependencyForAddSite = (): [string, string][] => {
  const fields = ["addressPrimary", "addressSecondary", "city", "state", "zipCode"]
  const combinations: [string, string][] = []

  fields.forEach((field1: string) => fields.forEach((field2: string) => combinations.push([field1, field2])))

  return combinations
}

export const addSiteValidation = yup.object().shape(
  {
    siteName: yup
      .string()
      .required("Name is Required")
      .min(1, "Name must not be smaller than 1 characters")
      .max(31, "Name must not be longer than 31 characters"),
    description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
    association: yup.number().nullable(),
    addressPrimary: yup.string().when(["addressPrimary", "addressSecondary", "city", "state", "zipCode"], {
      is: (addressPrimary: string, addressSecondary: string, city: string, state: string, zipCode: string) =>
        addressPrimary?.length || addressSecondary?.length || city?.length || state?.length || zipCode?.length,
      then: yup
        .string()
        .min(1, "Address must not be smaller than 1 character")
        .max(100, "Address must not be longer than 100 characters"),
    }),
    addressSecondary: yup.string().when(["addressPrimary", "addressSecondary", "city", "state", "zipCode"], {
      is: (addressPrimary: string, addressSecondary: string, city: string, state: string, zipCode: string) =>
        addressPrimary?.length || addressSecondary?.length || city?.length || state?.length || zipCode?.length,
      then: yup.string().max(100, "Address must not be longer than 100 characters"),
    }),
    country: yup.string().required("Country is Required"),
    city: yup.string().when(["addressPrimary", "addressSecondary", "city", "state", "zipCode"], {
      is: (addressPrimary: string, addressSecondary: string, city: string, state: string, zipCode: string) =>
        addressPrimary?.length || addressSecondary?.length || city?.length || state?.length || zipCode?.length,
      then: yup
        .string()
        .min(1, "City must not be smaller than 1 character")
        .max(31, "City must not be longer than 31 characters"),
    }),
    state: yup.string().when(["addressPrimary", "addressSecondary", "city", "state", "zipCode"], {
      is: (addressPrimary: string, addressSecondary: string, city: string, state: string, zipCode: string) =>
        addressPrimary?.length || addressSecondary?.length || city?.length || state?.length || zipCode?.length,
      then: yup
        .string()
        .min(1, "State must not be smaller than 1 character")
        .max(31, "State must not be longer than 31 characters"),
    }),
    zipCode: yup.string().when(["addressPrimary", "addressSecondary", "city", "state", "zipCode"], {
      is: (addressPrimary: string, addressSecondary: string, city: string, state: string, zipCode: string) =>
        addressPrimary?.length || addressSecondary?.length || city?.length || state?.length || zipCode?.length,
      then: yup
        .string()
        .min(3, "ZIP Code must not be smaller than 3 characters")
        .max(10, "ZIP Code must not be longer than 10 characters")
        .matches(/^[a-zA-Z30-9]*$/, "ZIP Code can only include alphanumeric characters"),
    }),
    parentId: yup.object().shape({ label: yup.string(), value: yup.string() }).nullable(),
  },
  getCyclicDependencyForAddSite(),
)

export const addSiteGroupValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .min(1, "Name must not be smaller than 1 characters")
    .max(31, "Name must not be longer than 31 characters"),
  description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
  association: yup.number().nullable(),
  parentId: yup.object().shape({ label: yup.string(), value: yup.string() }).nullable(),
})

export const addPrivateSaaSGeneralSettingsValidation = yup.object().shape({
  hostedSaaSService: yup.object().shape({ id: yup.string(), name: yup.string() }).required("Application is required"),
  site: yup.object().shape({ id: yup.string(), name: yup.string() }).required("Associate Site is required"),
  serviceConnector: yup
    .object()
    .shape({ id: yup.string(), name: yup.string() })
    .required("Associate service connector is Required"),
  serviceUrl: yup.string().required("Application Url is Required"),
  alias: yup
    .string()
    .required("Application Name is Required")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Application name must have only alphanumeric characters with interim space and hyphen",
    )
    .min(3, "Application Name must not be smaller than 3 characters")
    .max(31, "Application Name must not be longer than 31 characters"),
})

export const resetPasswordValidation = yup.object().shape({
  password: yup.string().required("Password is Required").matches(PASSWORD_REGEX, "Incorrect Password format"),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
})

export const addEditPolicyValidation = yup.object().shape({
  networkServiceDefaultPermission: yup.boolean(),
  policyType: yup.string(),
  name: yup
    .string()
    .required("Name is Required")
    .matches(SPACE_CHECK_REGEX, "Name cannot contain only space characters")
    .min(3, "Name must not be smaller than 3 characters")
    .max(31, "Name must not be longer than 31 characters"),
  description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
  // Access Groups
  userGroups: yup.array().test("checkUserGroup", "Select User Group", (value, { parent }) => {
    const { workspaceAllUsersAllowed }: AddEditPolicyType = parent
    if (workspaceAllUsersAllowed) {
      return true
    } else return !!value?.length
  }),
  deviceGroups: yup.array().test("checkDeviceGroup", "Select Device Group", (value, { parent }) => {
    const { workspaceAllDevicesAllowed, policyType }: AddEditPolicyType = parent
    if (policyType === "application") return true

    if (workspaceAllDevicesAllowed) {
      return true
    } else return !!value?.length
  }),
  // Applications Specific
  applicationsEnabled: yup.boolean(),
  applicationGroup: yup
    .object()
    .nullable()
    .when("policyType", {
      is: (policyType: string) => ["hybrid", "application"].includes(policyType),
      then: yup.object().nullable().required("Select Application Group"),
    }),
  // Network Specific
  selectVlan: yup.boolean(),
  vlanTextFieldDefaultVlanId: yup.string().when(["policyType", "selectVlan"], {
    is: (policyType: string, selectVlan: boolean) => ["hybrid", "network"].includes(policyType) && !selectVlan,
    then: yup
      .string()
      .nullable()
      .test(
        "isValidVlan",
        "Please enter a valid number between 1 and 4094",
        (value: any) => !value || (/^\d+$/.test(value) && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 4094),
      ),
    otherwise: yup.string().notRequired(),
  }),
  vlanSelectFieldXiqVlanProfileId: yup.string().when(["policyType", "selectVlan"], {
    is: (policyType: string, selectVlan: boolean) => ["hybrid", "network"].includes(policyType) && selectVlan,
    then: yup.string().required("VLAN ID is required"),
    otherwise: yup.string().notRequired(),
  }),
  isid: yup.string().when("policyType", {
    is: (policyType: string) => ["hybrid", "network"].includes(policyType),
    then: yup
      .string()
      .nullable()
      .test("isValidISID", "Please enter a valid number between 1 and 15999999", (value: any) => {
        return !value || (/^\d+$/.test(value) && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 15999999)
      }),
  }),
  apAware: yup.boolean(),
  workspaceAllUsersAllowed: yup.boolean(),
  workspaceAllDevicesAllowed: yup.boolean(),
  networkGroupsTableData: yup.array().of(
    yup
      .object({
        networkGroup: yup
          .object({
            value: yup.string(),
            label: yup.string(),
          })
          .nullable()
          .test("is-not-null", "Network Service Group is required", (value) => value !== null),
        permission: yup.boolean(),
      })
      .nullable(),
  ),
})

export const addEditApplicationGroupValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .matches(SPACE_CHECK_REGEX, "Name cannot contain only space characters")
    .min(3, "Name must not be smaller than 3 characters")
    .max(31, "Name must not be longer than 31 characters"),
  description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
})

export const editServiceConnectorValidation = (hostingProvider: keyof typeof mapHostIcon) => {
  const maxLimit = hostingProvider === "AZURE" ? 18 : hostingProvider === "AWS" || hostingProvider === "GCP" ? 26 : 31
  return yup.object().shape({
    connectorAlias: yup
      .string()
      .required("Connector Name is required")
      .min(3, "Connector Name must not be smaller than 3 characters")
      .max(maxLimit, `Connector Name must not be longer than ${maxLimit} characters`)
      .matches(ALPHA_NUMERIC_REGEX, "Invalid characters. Only alphanumeric and hyphens are allowed"),
    associatedSite: yup.object().required("Associated Site is required"),
    mtu: yup
      .string()
      .required("MTU is required")
      .matches(/^[0-9]+$/, "MTU must be a valid integer between 1200-1400 bytes")
      .test("range-check", "Allowed range for MTU is 1200-1400 bytes", (val: any) => {
        return parseInt(val) >= 1200 && parseInt(val) <= 1400
      }),
    autoScale: yup.boolean(),
  })
}

export const tenantAdminIDPValidation = (provider: IDPValueType, secureLdapAccess: boolean) =>
  yup.object().shape({
    ssoMethod: yup.string(),
    clientId: ["Azure", "OnPremAzure", "GSuite"].includes(provider)
      ? yup.string().when("ssoMethod", {
          is: "OIDC",
          then: yup.string().required("Client ID is Required"),
        })
      : yup.string().required("Client ID is Required"),
    clientSecret: ["Azure", "OnPremAzure", "GSuite"].includes(provider)
      ? yup.string().when("ssoMethod", {
          is: "OIDC",
          then: yup.string().required("Client Secret is Required"),
        })
      : yup.string().required("Client Secret is Required"),
    tenantId:
      provider === "Azure"
        ? yup.string().when("ssoMethod", {
            is: "OIDC",
            then: yup.string().required("Tenant ID is Required"),
          })
        : yup.string(),
    discoveryUrl:
      provider === "OnPremAzure"
        ? yup.string().when("ssoMethod", {
            is: "OIDC",
            then: yup.string().required("Discovery URL is Required"),
          })
        : yup.string(),

    loginUrl: ["Azure", "OnPremAzure", "GSuite"].includes(provider)
      ? yup.string().when("ssoMethod", {
          is: "SAML",
          then: yup
            .string()
            .required("Login URL is Required")
            .matches(
              /^(http|https):\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
              "Invalid Login URL",
            ),
        })
      : yup.string(),
    azureIdentifier: ["Azure", "OnPremAzure"].includes(provider)
      ? yup.string().when("ssoMethod", {
          is: "SAML",
          then: yup
            .string()
            .required(`Microsoft ${provider === "Azure" ? "Entra ID" : "ADFS"} Identifier is Required`)
            .matches(
              /^(http|https):\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
              `Invalid Microsoft ${provider === "Azure" ? "Entra ID" : "ADFS"} Identifier`,
            ),
        })
      : yup.string(),
    gsuiteIdentifier:
      provider === "GSuite"
        ? yup.string().when("ssoMethod", {
            is: "SAML",
            then: yup
              .string()
              .required("Entity ID Identifier is Required")
              .matches(
                /^(http|https):\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
                "Invalid Entity ID Identifier",
              ),
          })
        : yup.string(),
    syncFrequency: yup.string(),
    customerId:
      provider === "GSuite"
        ? yup.string().when("syncEnabled", {
            is: true,
            then: yup.string().required("Customer ID is required"),
            otherwise: yup.string(),
          })
        : yup.string(),

    raasClientId: yup.string().when("secureNetworkAccess", {
      is: true,
      then: yup.string().when("useZtnaAppCreds", {
        is: false,
        then: yup.string().required("Client ID is Required"),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().optional(),
    }),
    raasSecretKey: yup.string().when("secureNetworkAccess", {
      is: true,
      then: yup.string().when("useZtnaAppCreds", {
        is: false,
        then: yup.string().required("Secret Key is Required"),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().optional(),
    }),
    raasTenantId:
      provider === "Azure"
        ? yup.string().when("secureNetworkAccess", {
            is: true,
            then: yup.string().when(["useZtnaAppCreds", "ssoMethod"], {
              is: (useZtnaAppCreds: boolean, ssoMethod: string) => useZtnaAppCreds === false && ssoMethod === "OIDC",
              then: yup.string().required("Tenant ID is Required"),
              otherwise: yup.string().nullable(),
            }),
            otherwise: yup.string().optional(),
          })
        : yup.string(),
    raasDiscoveryUrl:
      provider === "OnPremAzure"
        ? yup.string().when("secureNetworkAccess", {
            is: true,
            then: yup.string().when("useZtnaAppCreds", {
              is: false,
              then: yup.string().required("Discovery URL is Required"),
              otherwise: yup.string().optional().nullable(),
            }),
            otherwise: yup.string().nullable(),
          })
        : yup.string(),
  })

export const superAdminIDPValidation = (provider: "google" | "microsoft") =>
  yup.object().shape({
    clientId: yup.string().required("Client ID is Required"),
    clientSecret: yup.string().required("Client Secret is Required"),
    tenantId: provider === "microsoft" ? yup.string().required("Tenant ID is Required") : yup.string(),
  })

const awsAccountIdValidation = yup.object().shape({
  awsAccountId: yup
    .string()
    .required("AWS Account ID is Required")
    .matches(AWS_ACCOUNT_ID_REGEX, "Invalid AWS Account ID")
    .min(12, "ID must contain 12 digits")
    .max(12, "ID must not be longer than 12 digits"),
  integrationName: yup
    .string()
    .required("Integration Name is Required")
    .min(3, "Integration Name must not be smaller than 3 characters")
    .max(31, "Integration Name must not be longer than 31 characters"),
})

export const awsAccountAccessCredsValidation = yup.object().shape({
  awsAccessKeyId: yup.string().required("AWS Access Key ID is Required"),
  awsSecret: yup.string().required("AWS Secret is Required"),
  sessionToken: yup.string().required("Session Token is Required"),
})

export const awsAccountIntegrationValidation = awsAccountIdValidation.concat(awsAccountAccessCredsValidation)

export const azureCloudFormationStackValidation = yup.object().shape({
  subscriptionId: yup
    .string()
    .required("Subscription ID is Required")
    .matches(AZURE_SUBSCRIPTION_ID_REGEX, "Invalid Subscription ID")
    .min(36, "Subscription ID must contain 36 characters")
    .max(36, "Subscription ID must not be longer than 36 characters"),
  tenantId: yup
    .string()
    .required("Tenant ID is Required")
    .matches(AZURE_SUBSCRIPTION_ID_REGEX, "Invalid Tenant ID")
    .min(36, "Tenant ID must contain 36 characters")
    .max(36, "Tenant ID must not be longer than 36 characters"),
  clientId: yup
    .string()
    .required("Client ID is Required")
    .matches(AZURE_SUBSCRIPTION_ID_REGEX, "Invalid Client ID")
    .min(36, "Client ID must contain 36 characters")
    .max(36, "Client ID must not be longer than 36 characters"),

  clientSecret: yup.string().required("Client Secret is Required"),
  objectId: yup
    .string()
    .required("Object ID is Required")
    .matches(AZURE_SUBSCRIPTION_ID_REGEX, "Invalid Object ID")
    .min(36, "Object ID must contain 36 characters")
    .max(36, "Object ID must not be longer than 36 characters"),
  integrationName: yup
    .string()
    .required("Integration Name is Required")
    .min(3, "Integration Name must not be smaller than 3 characters")
    .max(31, "Integration Name must not be longer than 31 characters"),
})

export const GCPCloudTemplateValidation = yup.object().shape({
  projectID: yup
    .string()
    .required("Project ID is Required")
    .min(3, "Project ID must not be smaller than 3 characters")
    .max(31, "Project ID must not be longer than 31 characters"),
  integrationName: yup
    .string()
    .required("Integration Name is Required")
    .min(3, "Integration Name must not be smaller than 3 characters")
    .max(31, "Integration Name must not be longer than 31 characters"),
})

export const editIntegrationValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is Required")
    .min(3, "Name must not be smaller than 3 characters")
    .max(31, "Name must not be longer than 31 characters"),
})

export const addMultiCloudHostValidation = yup.object().shape({
  hostingProvider: yup.string().required("Cloud Hosting Provider is required"),
  site: yup.object().shape({ id: yup.string(), name: yup.string() }).nullable().required("Site is required"),
  mtu: yup
    .string()
    .required("MTU is required")
    .matches(/^[0-9]+$/, "MTU must be a valid integer between 1200-1400 bytes")
    .test(
      "range-check",
      "Allowed range for MTU is 1200-1400 bytes",
      (val: any) => parseInt(val) >= 1200 && parseInt(val) <= 1400,
    ),
  connectorName: yup
    .string()
    .required("Connector Name is required")
    .when("hostingProvider", {
      is: (val: any) => val === "GCP",
      then: yup
        .string()
        .min(7, "Connector Name must not be smaller than 7 characters")
        .max(21, "Connector Name must not be longer than 21 characters")
        .matches(
          GCP_CONNECTOR_NAME_REGEX,
          "Connector Name must contain and start with a lower case letter and can contain hyphens separated alphanumeric characters",
        ),
      otherwise: yup.string().when("hostingProvider", {
        is: (val: any) => val === "AZURE",
        then: yup
          .string()
          .min(3, "Connector Name must not be smaller than 3 characters")
          .max(18, "Connector Name must not be longer than 18 characters")
          .matches(
            ALPHA_NUMERIC_REGEX,
            "Connector Name must contain and start with a lower case letter and can contain hyphens separated alphanumeric characters",
          ),
        otherwise: yup
          .string()
          .min(3, "Connector Name must not be smaller than 3 characters")
          .max(26, "Connector Name must not be longer than 26 characters")
          .matches(
            ALPHA_NUMERIC_REGEX,
            "Connector Name must contain and start with a lower case letter and can contain hyphens separated alphanumeric characters",
          ),
      }),
    }),
  autoScale: yup.boolean(),
})
export const editPrivateSaaSValidation = yup.object().shape({
  alias: yup
    .string()
    .required("Application Name is Required")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Application name must have only alphanumeric characters with interim space and hyphen",
    )
    .min(3, "Application Name must not be smaller than 3 characters")
    .max(31, "Application Name must not be longer than 31 characters"),
})

export const editMultiCloudServiceValidation = yup.object().shape({
  alias: yup
    .string()
    .required("Application Name is Required")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Application name must have only alphanumeric characters with interim space and hyphen",
    )
    .min(3, "Application Name must not be smaller than 3 characters")
    .max(31, "Application Name must not be longer than 31 characters"),
})

export const addAWSServiceConnectorValidation = yup.object().shape({
  vpcId: yup.string().required("VPC ID is required"),
  instanceType: yup.string().required("Instance type is required"),
  subnet: yup.string().required("Subnet is required"),
  keyPair: yup.string().required("Key pair is required"),
})

export const addAzureServiceConnectorValidation = yup.object().shape({
  VMType: yup.string().required("VM type is required"),
  subnet: yup.string().required("Subnet is required"),
  vnetId: yup.string().required("VNET ID is required"),
  keyPair: yup
    .object()
    .shape({ keyName: yup.string(), keyValue: yup.string() })
    .typeError("Key pair is required")
    .required("Key pair is required"),
})

export const addGCPServiceConnectorValidation = yup.object().shape({
  VMType: yup.string().required("VM type is required"),
  subnet: yup.string().required("Subnet is required"),
  region: yup.string().required("Region is required"),
  VPC: yup.string().required("VPC is required"),
  availabilityZone: yup.string().required("Availability Zone is required"),
  keyPair: yup
    .object()
    .shape({ keyName: yup.string(), keyValue: yup.string() })
    .typeError("Key pair is required")
    .required("Key pair is required"),
})

export const inviteUsersValidation = yup.object().shape({
  userEmail: yup.string().when("selectedUserEmails", {
    is: (val: string[]) => val?.length === 0,
    then: yup.string().required("User Email Address is required").matches(EMAIL_REGEX, "Invalid Email Address"),
    otherwise: yup
      .string()
      .test("email-address", "Invalid Email Address", (value) => (value ? EMAIL_REGEX.test(value) : true)),
  }),
  selectedUserEmails: yup.array().of(yup.string()),
  accessGroupId: yup.string().required("User Group is Required"),
})

export const globalTimeoutValidation = yup.object().shape({
  timeout: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(300, "Session timeout value should be greater than or equal to 300")
    .max(172800, "Session timeout value should be less than or equal to 172800")
    .when("$defaultTimeout", (defaultTimeout, schema) => {
      return defaultTimeout
        ? schema.nullable().notRequired()
        : schema.nullable().required("Session timeout is required")
    }),
})

export const addEditRadiusTemplateValidation = yup.object().shape({
  name: yup.string().required("Name value is required"),
  description: yup.string().required().max(200, "Description must not be longer than 200 characters"),
  vsaList: yup
    .array()
    .transform((value) => (value === null ? [] : value))
    .required("Select RADIUS VSA and variables"),
})

export const updateRadiusTemplateNetworkDevicesValidation = yup.object().shape({
  radiusTemplate: yup.string().required("Please select RADIUS Template"),
})

export const addEditNetworkDevicesValidation = yup.object().shape({
  ipAddress: yup
    .string()
    .required("IP Address is Required")
    .test("no-white-space", "IP Address contains invalid whitespace", (value) => {
      const trimmedValue = (value || "").trim()
      return !WHITE_SPACE_CHECK.test(trimmedValue)
    })
    .test("valid-ip-address", "Invalid IP Address", (value) => {
      const trimmedValue = (value || "").trim()
      return IP_ADDRESS_REGEX.test(trimmedValue)
    })
    .test("not-restricted", "IP Address is restricted", (value) => {
      const trimmedValue = (value || "").trim()
      return !isInvalidIPAddress(trimmedValue)
    })
    .test("cidr-notation", "Invalid CIDR Notation", (value) => {
      const trimmedValue = (value || "").trim()
      return CIDR_NOTATION_REGEX.test(trimmedValue)
    }),
  type: yup.string().required("Type is Required"),
  radsecStatus: yup.string().required(),
  sharedSecret: yup.string().when("radsecStatus", {
    is: "secret",
    then: yup
      .string()
      .required("Shared secret is required")
      .matches(/^[a-zA-Z0-9]*$/, "Shared Secret can only include alphanumeric characters")
      .min(3, "Please ensure shared secret is between 3 and 32 characters in length")
      .max(32, "Please ensure shared secret is between 3 and 32 characters in length"),
    otherwise: yup.string().notRequired(),
  }),
  radiusTemplate: yup.string().trim().required("Please select RADIUS Template"),
  sessionTimeout: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(300, "Session timeout value should be greater than or equal to 300")
    .max(172800, "Session timeout value should be less than or equal to 172800"),
  site: yup.string().trim().required("Please select Site"),
})

export const importNetworkDevicesValidation = yup.object().shape({
  radiusTemplate: yup.string().trim().required("Please select RADIUS Template"),
  site: yup.string().trim().required("Please select Site"),
  sharedSecret: yup
    .string()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .matches(/^[a-zA-Z0-9]*$/, "Shared Secret can only include alphanumeric characters")
    .min(3, "Please ensure shared secret is between 3 and 32 characters in length")
    .max(32, "Please ensure shared secret is between 3 and 32 characters in length"),
})

export const superAdminLoginValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required")
    .max(320, "Email must not be longer than 320 characters")
    .matches(EMAIL_REGEX, "Email is Invalid")
    .email("Invalid email format. Enter a valid email address."),
  password: yup.string().required("Password is Required"),
})

export const splunkValidation = yup.object().shape({
  hecHost: yup
    .string()
    .required("HTTP Event Collector Host is required")
    .test("space-check", "HTTP Event Collector Host must be without spaces", (str: any) => {
      const re = /^\S+$/g
      return re.test(str)
    }),
  port: yup
    .string()
    .required("Port is required.")
    .matches(PORT_REGEX, "Please enter a valid port number i.e [0-64355]")
    .test("port-validity", "Please enter a valid port number i.e [0-64355]", (value: any) => {
      return !(Number.isNaN(Number(value)) || value.includes(".") || value.includes(" "))
    }),
  protocol: yup.string().required("Protocol Group is Required"),
  authToken: yup.string().required("Authentication Token Group is Required"),
})

export const deployRelayNodeValidation = yup.object().shape({
  relayName: yup
    .string()
    .required("UZTNA Gateway name is required")
    .min(3, "UZTNA Gateway name must be between 3-16 characters")
    .max(16, "UZTNA Gateway name must be between 3-16 characters")
    .matches(ALPHA_NUMERIC_REGEX, "Invalid characters. Only alphanumeric and hyphens are allowed"),
  instance: yup.string().required("Instance type is required"),
  regionName: yup.string().required("Region is Required"),
})

export const publicSaasServiceProviderValidation = yup.object().shape({
  errorMessage: yup.string(),
  formType: yup.string(),
  serviceId: yup.number(),
  stepOneInputFields: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      required: yup.boolean(),
      min: yup.number(),
      max: yup.number(),
      value: yup.string().test("check-required", "required field", (value, { parent, path, createError }) => {
        let { label, required, min = 0, max = 0, key }: FormFieldType = parent
        label = label.replace("Service URL", "Application URL")
        if (required === true && (value === undefined || value === null || value === "")) {
          return createError({ message: `${label} is a required field`, path: path })
        }
        if ((min || max) && value?.length && (value?.length < min || value?.length > max)) {
          if (min > 0) {
            return createError({ message: `${label} must be between ${min}-${max} characters`, path: path })
          }
          return createError({ message: `${label} must not be longer than ${max} characters`, path: path })
        }
        if (key.toLocaleLowerCase().includes("url") && typeof value === "string" && !SAAS_APP_URL_REGEX.test(value)) {
          return createError({ message: `${label} is not a valid URL`, path: path })
        }

        if (key.toLocaleLowerCase().includes("xml") && typeof value === "string" && !XML_FORMAT_REGEX.test(value)) {
          return createError({ message: `${label} is not valid XML`, path: path })
        }
        return true
      }),
    }),
  ),
  stepTwoInputFields: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      required: yup.boolean(),
      value: yup.string().test("check-required", "required field", (value, { parent, path, createError }) => {
        const { label, required, min = 0, max = 0, key }: FormFieldType = parent
        if (required === true && (value === undefined || value === null || value === "")) {
          return createError({ message: `${label} is a required field`, path: path })
        }
        if ((min || max) && value?.length && (value?.length < min || value?.length > max)) {
          return createError({ message: `${label} must be between ${min}-${max} characters`, path: path })
        }
        if (key.toLocaleLowerCase().includes("url") && typeof value === "string" && !SAAS_APP_URL_REGEX.test(value)) {
          return createError({ message: `${label} is not a valid URL`, path: path })
        }

        if (key.toLocaleLowerCase().includes("xml") && typeof value === "string" && !XML_FORMAT_REGEX.test(value)) {
          return createError({ message: `${label} is not valid XML`, path: path })
        }
        return true
      }),
    }),
  ),
  url: yup.string(),
})

export const addUpdateMSIntuneIntegrationValidation = yup.object().shape({
  clientId: yup.string().required("Client ID is Required"),
  clientSecret: yup.string().required("Client Secret is Required"),
  tenantId: yup.string().required("Tenant ID is Required"),
})

const validationLocationBasedConditionMethod = (
  networkLocationOptions: AddEditLocationConditionFormType["networkLocation"][],
) => {
  return (value: any, ctx: any) => {
    const { networkLocation } = ctx.parent as AddEditLocationConditionFormType
    if (networkLocationOptions.includes(networkLocation)) {
      return !!value?.length
    }
    return true
  }
}

export const addEditLocationConditionValidation = (enableCraas: boolean) =>
  yup.object().shape({
    name: yup
      .string()
      .required("Condition Name is required")
      .min(3, "Name must not be smaller than 3 characters")
      .max(31, "Name must not be longer than 31 characters")
      .matches(
        ALPHANUMERIC_SPACE_HYPHEN_REGEX,
        "Condition name must have only alphanumeric characters with interim space and hyphen",
      ),
    description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
    // Location Condition Fields
    allLocations: yup.boolean(),
    userLocations: yup.array().test("checkUserLocations", "User Location is required", (value, ctx) => {
      const { allLocations, networkLocation } = ctx.parent as AddEditLocationConditionFormType
      if (!allLocations && !networkLocation) {
        return !!value?.length
      }
      return true
    }),
    networkLocation: enableCraas
      ? yup
          .string()
          .nullable()
          .test("checkNetworkLocations", "Network Location is required", (value, ctx) => {
            const { allLocations, userLocations } = ctx.parent as AddEditLocationConditionFormType
            if (!allLocations && !userLocations) {
              return !!value
            }
            return true
          })
      : yup.string().nullable(),
    ssids: yup
      .array()
      .test("checkSSIDs", "SSID is required", validationLocationBasedConditionMethod(["SSID"]))
      .test("checkSSIDs", "SSID is required", validationLocationBasedConditionMethod(["ACCESS_POINT_AND_SSID"])),
    switches: yup
      .array()
      .test("checkSwitches", "Switch is required", validationLocationBasedConditionMethod(["SWITCH"])),
    sites: yup.array().test("checkSites", "Site is required", validationLocationBasedConditionMethod(["SITE"])),
    accessPoints: yup
      .array()
      .test("checkAccessPoints", "Access Point is required", validationLocationBasedConditionMethod(["ACCESS_POINT"]))
      .test(
        "checkAccessPoints",
        "Access Point is required",
        validationLocationBasedConditionMethod(["ACCESS_POINT_AND_SSID"]),
      ),
  })

export const addEditTimeConditionValidation = yup.object().shape({
  id: yup.string(),
  name: yup
    .string()
    .required("Condition Name is required")
    .min(3, "Name must not be smaller than 3 characters")
    .max(31, "Name must not be longer than 31 characters")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Condition name must have only alphanumeric characters with interim space and hyphen",
    ),
  description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
  dateTime: yup.object().shape({
    startDate: yup
      .object()
      .nullable()
      .test("check-required", "Select Start Date & Time", (value: any, { parent, path, createError, ...ctx }) => {
        const { endDate } = parent
        const [, , ctxParent] = (ctx as any)?.from
        const { id, timezone } = ctxParent.value

        if (!value) {
          return createError({ message: "Start Date is required", path: path })
        }

        if (parseTimezone(timezone, endDate).isBefore(value, "days") && endDate) {
          return createError({ message: "Start Date must be earlier than End Date", path: path })
        }
        if (parseTimezone(timezone).isAfter(value, "days") && !id) {
          return createError({ message: "Start date must be today or in the future", path: path })
        }

        return value
      }),
    startTime: yup
      .object()
      .nullable()
      .test("check-required", "Start Time is Required", (value: any, { parent, path, createError, ...ctx }) => {
        const { endTime, endDate, startDate } = parent
        const [, , ctxParent] = (ctx as any)?.from
        const { repeat, timezone } = ctxParent.value

        if (!value) {
          return createError({ message: "Start Time is required", path: path })
        }
        /**
         * Trigger message "Start Time must be earlier than end time" when
         * 1. Start Time comes after end Time
         * 2. (Repeat Type is Do not repeat (repeat = falsy) And Condition time span is on same day i.e. End Date is same as Start Date) OR (Repeat Type is selected (repeat = truthy even if end date or ends on date is provided as same day as start date))
         */
        if (
          (parseTimezone(timezone, endTime).format("HH:mm:ss") < parseTimezone(timezone, value).format("HH:mm:ss") &&
            endTime &&
            parseTimezone(timezone, startDate).isSame(endDate, "day") &&
            !repeat &&
            endDate) ||
          (!repeat &&
            !endDate &&
            endTime &&
            parseTimezone(timezone, endTime).format("HH:mm:ss") < parseTimezone(timezone, value).format("HH:mm:ss"))
        ) {
          return createError({ message: "Start Time must be earlier than End Time", path: path })
        }

        return value
      }),
    endDate: yup
      .object()
      .nullable()
      .test(
        "check-endDate-after-startDate",
        "End Date must be later than Start Date",
        (value: any, { parent, path, createError, ...ctx }) => {
          const { startDate } = parent
          const [, , ctxParent] = (ctx as any)?.from
          const { timezone } = ctxParent.value

          if (value && parseTimezone(timezone, value).isBefore(startDate, "days") && startDate) {
            return false
          } else {
            return true
          }
        },
      ),
    endTime: yup
      .object()
      .nullable()
      .test("check-required", "End Time is Required", (value: any, { parent, path, createError, ...ctx }) => {
        const { startTime, startDate, endDate, endTime } = parent
        const [, , ctxParent] = (ctx as any)?.from
        const { repeat, timezone } = ctxParent.value

        if (!value) {
          return createError({ message: "End Time is required", path: path })
        }
        /**
         * Trigger error message "End Time must be later than Start time" when
         * 1. End Time is earlier than Start Time
         * 2. (Repeat Type is Do not repeat (repeat = falsy) And Condition time span is on same day End Date is same as Start Date) OR (Repeat Type is selected (repeat = truthy even if end date or ends on date is provided as same day as start date))
         */
        if (
          (startTime &&
            parseTimezone(timezone, value).format("HH:mm:ss") < parseTimezone(timezone, startTime).format("HH:mm:ss") &&
            // parseTimezone(timezone).isSame(startDate, "day") &&
            ((parseTimezone(timezone, startDate).isSame(endDate, "day") && !repeat && endDate) || repeat)) ||
          (!repeat &&
            !endDate &&
            endTime &&
            parseTimezone(timezone, value).format("HH:mm:ss") < parseTimezone(timezone, startTime).format("HH:mm:ss")) // For the case when repeat is not selected and end date is not provided
        ) {
          return createError({ message: "End Time must be later than Start Time", path: path })
        }
        /**
         * Trigger error message "End Time must be later than current time" when
         * 1. End Time is greater than current time
         * 2. Condition is starting today (start time = today's date)
         * 3. (Repeat Type is Do not repeat (repeat = falsy) And Condition time span is on same day End Date is same as Start Date) OR (Repeat Type is selected (repeat = truthy even if end date or ends on date is provided as same day as start date))
         */
        if (
          parseTimezone(timezone).format("HH:mm:ss") >= parseTimezone(timezone, value).format("HH:mm:ss") &&
          parseTimezone(timezone).isSame(startDate, "day") &&
          parseTimezone(timezone, startDate).isSame(endDate, "day") &&
          !repeat &&
          endDate
        ) {
          return createError({ message: "End Time must be later than current time", path: path })
        }

        return value
      }),
  }),
  timezone: yup.string().required(),
})

export const addEditAuthConditionValidation = yup.object().shape({
  name: yup
    .string()
    .required("Condition Name is required")
    .min(3, "Name must not be smaller than 3 characters")
    .max(31, "Name must not be longer than 31 characters")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Condition name must have only alphanumeric characters with interim space and hyphen",
    ),
  description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
  authenticationMethods: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .min(1, "Authentication Method is required"),
})

export const addNetworkValidation = yup.object().shape({
  networkName: yup
    .string()
    .required("Network service name is Required")
    .min(1, "Name must not be smaller than 1 characters")
    .max(31, "Name must not be longer than 31 characters")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Network service name must have only alphanumeric characters with interim space and hyphen",
    ),
  ipAddress: yup.string().when("ipType", {
    is: "IPv6",
    then: yup
      .string()
      .required("IP Address is Required")
      .test("no-white-space", "No white space allowed", (value) => !WHITE_SPACE_CHECK.test(value || ""))
      .test("ipv6-validity-check", "IP Address is invalid", (str) => {
        const regex1 = IPV6_REGEX
        const regex2 = IPV6_CIDR_NOTATION_REGEX
        return regex1.test(str || "") || regex2.test(str || "")
      }),
    otherwise: yup
      .string()
      .required("IP Address is Required")
      .test(
        "no-white-space",
        "Please ensure IP address is in the format xxx.xxx.xxx.xxx with each octet between 0 and 255, and the subnet mask is in CIDR notation ranges from 0-32 (e.g., /24); for example, 192.168.1.1/24",
        (value) => {
          const trimmedValue = (value || "").replace(/\s+$/, "")
          return !WHITE_SPACE_CHECK.test(trimmedValue)
        },
      )
      .test(
        "ipv4-validity-check",
        "Please ensure IP address is in the format xxx.xxx.xxx.xxx with each octet between 0 and 255, and the subnet mask is in CIDR notation ranges from 0-32 (e.g., /24); for example, 192.168.1.1/24",
        (str) => {
          const regex1 = IP_ADDRESS_REGEX
          const regex2 = CIDR_NOTATION_REGEX
          const trimmedStr = (str || "").replace(/\s+$/, "")
          return regex1.test(trimmedStr || "") || regex2.test(trimmedStr || "")
        },
      ),
  }),
  ports: yup.string().when("protocol", {
    is: (value: string) => PORT_NOT_REQUIRED_PROTOCOLS?.includes(value),
    then: yup.string(),
    otherwise: yup.string().required("Port is required").test("is-valid-port", handlePortsValidation),
  }),
})

export const addNetworkGroupValidation = yup.object().shape({
  networkGroupName: yup
    .string()
    .required("Network service group name is Required")
    .min(1, "Name must not be smaller than 1 characters")
    .max(31, "Name must not be longer than 31 characters"),
  description: yup.string().nullable().max(200, "Description must not be longer than 200 characters"),
})

export const addSSIDValidation = yup.object().shape({
  ssidId: yup
    .string()
    .required("SSID name is Required")
    .min(3, "SSID name must not be smaller than 3 characters")
    .max(31, "SSID name must not be longer than 31 characters"),
  ssidName: yup
    .string()
    .required("Broadcast name is Required")
    .max(200, "Broadcast name must not be longer than 200 characters"),
})

export const updateApplicationValidation = yup.object().shape({
  name: yup
    .string()
    .required("Application Name is Required")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Application name must have only alphanumeric characters with interim space and hyphen",
    )
    .min(3, "Application Name must not be smaller than 3 characters")
    .max(31, "Application Name must not be longer than 31 characters"),
})

export const addNetworkPolicyValidation = yup.object().shape({
  mac_address: yup.string().required("MAC Address is required").matches(MAC_ADDRESS_REGEX, "Invalid MAC Address"),
  auth_type: yup.string().required("Authentication Type is Required"),
  switch_ip: yup
    .string()
    .optional()
    .test("space-check", "Enter correct format of IP address", (str: any) => {
      const re = IP_ADDRESS_REGEX
      return str === undefined || str === "" || re.test(str)
    }),
  switch_port: yup
    .string()
    .optional()
    .test("space-check", "Invalid input for Switch Port", (str: any) => {
      const re = AWS_ACCOUNT_ID_REGEX
      return str === undefined || str === "" || re.test(str)
    }),
})

export const addApplicationPolicyEvaluationValidation = yup.object().shape({
  user: yup.object().shape({ id: yup.string(), email: yup.string().min(1, "User is required") }),
  application: yup.string().required("Application is required"),
  device: yup.string().when("accessMode", {
    is: (val: any) => val === "Agentbased",
    then: yup.string().required("Device is required"),
    otherwise: yup.string(),
  }),
  accessMode: yup.string().required("Access Mode is required"),
  location: yup.array().of(yup.object().shape({ id: yup.string(), name: yup.string(), label: yup.string() })),
  timeZone: yup.string(),
  startTime: yup.object().nullable(),
  endTime: yup.object().nullable(),
})

export const syncXIQProfilesPoliciesValidation = yup.object().shape({
  selectedProfiles: yup.array().of(yup.mixed()),
  selectedPolicies: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Name is Required")
        .matches(SPACE_CHECK_REGEX, "Name cannot contain only space characters")
        .min(3, "Name must not be smaller than 3 characters")
        .max(31, "Name must not be longer than 31 characters"),
      description: yup.string().nullable().max(500, "Description must not be longer than 500 characters"),
      // Access Groups
      userGroups: yup.array().test("checkUserGroup", "Select User Group", (value, { parent }) => {
        const { deviceGroups }: AddEditPolicyType = parent
        return !!value?.length && !!deviceGroups.length
      }),
      deviceGroups: yup.array().test("checkDeviceGroup", "Select Device Group", (value, { parent }) => {
        const { userGroups }: AddEditPolicyType = parent

        return !!value?.length && !!userGroups.length
      }),
      // Network Specific
      vlan: yup.string(),
      accessGroupsInfo: yup.array().of(yup.object()),
      conditionsInfo: yup.array().of(yup.object()),
    }),
  ),
})

export const addPrivateApplicationValidation = (isEdit: boolean, isDiscovery?: boolean) =>
  isEdit
    ? yup.object().shape({
        alias: yup
          .string()
          .required("Application Name is Required")
          .matches(
            ALPHANUMERIC_SPACE_HYPHEN_REGEX,
            "Application name must have only alphanumeric characters with interim space and hyphen",
          )
          .min(3, "Application Name must not be smaller than 3 characters")
          .max(31, "Application Name must not be longer than 31 characters"),
        protocol: yup.string().when("applicationType", {
          is: (val: any) => !isDiscovery,
          then: yup.string(),
          otherwise: yup.string().required("Protocol is Required"),
        }),
      })
    : yup.object().shape({
        protocol: yup.string().when("applicationType", {
          is: (val: any) =>
            [ApplicationTypes.PRIVATE_WEB_APP, ApplicationTypes.MULTI_CLOUD_WEB_APP].includes(val) || !val,
          then: yup.string(),
          otherwise: yup.string().required("Protocol is Required"),
        }),
        url: yup.string().when("applicationType", {
          is: (val: any) => [ApplicationTypes.PRIVATE_WEB_APP].includes(val) || !val,
          then: yup
            .string()
            .matches(
              /^(http|https):\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=!]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=!]*)$/,
              "Invalid Application URL",
            ),
          otherwise: yup.string(),
        }),
        // Web App General Settings
        alias: yup
          .string()
          .required("Application Name is Required")
          .matches(
            ALPHANUMERIC_SPACE_HYPHEN_REGEX,
            "Application name must have only alphanumeric characters with interim space and hyphen",
          )
          .min(3, "Application Name must not be smaller than 3 characters")
          .max(31, "Application Name must not be longer than 31 characters"),
        site: yup.object({ id: yup.string(), name: yup.string() }).nullable().required("Associate Site is required"),
        serviceConnector: yup
          .object({ id: yup.string(), name: yup.string() })
          .nullable()
          .required("Associate service connector is Required"),
        // SSH/Terminal App Settings Agent Based
        hostname: yup.string().when("applicationType", {
          is: (val: any) =>
            [ApplicationTypes.PRIVATE_WEB_APP, ApplicationTypes.MULTI_CLOUD_WEB_APP].includes(val) || !val,
          then: yup.string(),
          otherwise: yup
            .string()
            .required("Hostname or IP address is required")
            .test("ip-hostname-test", "Hostname or IP address is invalid", (str: any) => {
              const re = IP_ADDRESS_OR_HOSTNAME_REGEX
              return re.test(str)
            }),
        }),
        port: yup.string().when("applicationType", {
          is: (val: any) =>
            [ApplicationTypes.PRIVATE_WEB_APP, ApplicationTypes.MULTI_CLOUD_WEB_APP].includes(val) || !val,
          then: yup.string(),
          otherwise: yup
            .string()
            .required("Port is required.")
            .matches(PORT_REGEX, "Please enter a valid port number i.e [0-64355]")
            .test("port-validity", "Please enter a valid port number i.e [0-64355]", (value: any) => {
              return !(Number.isNaN(Number(value)) || value.includes("."))
            }),
        }),
        // RDP/VPN App Settings
        password: yup.string().when("authenticationMethod", {
          is: "managed",
          then: yup.string().required("Password is required"),
          otherwise: yup.string(),
        }),
        username: yup.string().when("authenticationMethod", {
          is: "managed",
          then: yup.string().when("serviceProtocol", {
            is: "rdp",
            then: yup
              .string()
              .required("Username is required")
              .min(3, "Username must not be smaller than 3 characters")
              .max(31, "Username must not be longer than 31 characters"),
          }),
          otherwise: yup.string(),
        }),
        passphrase: yup.string().nullable(),
        // Multi Cloud Web App
        hostingProvider: yup.string().when("applicationType", {
          is: ApplicationTypes.MULTI_CLOUD_WEB_APP,
          then: yup.string().required("Hosting Provider is required"),
          otherwise: yup.string(),
        }),
        loadBalancer: yup.object().when("applicationType", {
          is: ApplicationTypes.MULTI_CLOUD_WEB_APP,
          then: yup
            .object()
            .shape({ id: yup.string(), name: yup.string() })
            .required("Load Balancer is required")
            .nullable(),
          otherwise: yup.object().shape({ id: yup.string(), name: yup.string() }),
        }),
        // Extra
        accessType: yup.string(),
        applicationType: yup.string(),
        isUrlHidden: yup.string(),
        serviceProtocol: yup.string(),
        authenticationMethod: yup.string(),
        type: yup.string(),
        privateKey: yup.string(),
        authenticationType: yup.string(),
      })
export const addUpdateDnsServerValidation = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must not be smaller than 3 characters")
    .max(250, "Name must not be longer than 250 characters")
    .matches(
      ALPHANUMERIC_SPACE_HYPHEN_REGEX,
      "Name must have only alphanumeric characters with interim space and hyphen",
    ),
  ipAddress: yup
    .string()
    .required("IP Address is Required")
    .test("no-white-space", "Invalid IP Address", (value) => {
      const trimmedValue = (value || "").replace(/\s+$/, "")
      return !WHITE_SPACE_CHECK.test(trimmedValue)
    })
    .test("ipv4-validity-check", "Invalid IP Address", (str) => {
      const regex1 = IP_ADDRESS_REGEX
      const regex2 = CIDR_NOTATION_REGEX
      const trimmedStr = (str || "").replace(/\s+$/, "")
      return regex1.test(trimmedStr || "") || regex2.test(trimmedStr || "")
    }),
  port: yup
    .string()
    .required("Port is Required.")
    .matches(PORT_REGEX, "Please enter a valid port number i.e [0-64355]")
    .test("port-validity", "Please enter a valid port number i.e [0-64355]", (value: any) => {
      return !(Number.isNaN(Number(value)) || value.includes(".") || value.includes(" "))
    }),
  serviceConnector: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .nullable()
    .required("Service Connector is Required"),
})

export const addUpdateDnsPolicyValidation = (primaryDnsType: string) =>
  yup.object({
    name: yup
      .string()
      .required("Name is required")
      .min(3, "Name must not be smaller than 3 characters")
      .max(250, "Name must not be longer than 250 characters")
      .matches(
        ALPHANUMERIC_SPACE_HYPHEN_REGEX,
        "Name must have only alphanumeric characters with interim space and hyphen",
      ),
    condition: yup
      .object()
      .shape({ label: yup.string(), value: yup.string() })
      .required("Location Condition is Required"),
    primaryDnsIp:
      primaryDnsType === "primaryPrivate"
        ? yup.string().optional()
        : yup
            .string()
            .required("IP Address is Required")
            .test("no-white-space", "Invalid IP Address", (value) => {
              const trimmedValue = (value || "").replace(/\s+$/, "")
              return !WHITE_SPACE_CHECK.test(trimmedValue)
            })
            .test("ipv4-validity-check", "Invalid IP Address", (str) => {
              const regex1 = IP_ADDRESS_REGEX
              const regex2 = CIDR_NOTATION_REGEX
              const trimmedStr = (str || "").replace(/\s+$/, "")
              return regex1.test(trimmedStr || "") || regex2.test(trimmedStr || "")
            }),
    primaryDnsServer:
      primaryDnsType === "primaryPublic" ? yup.string().optional() : yup.string().required("DNS server is Required"),
    secondaryDnsIp: yup
      .string()
      .test(
        "optional-or-valid-ip",
        "Invalid IP Address",
        (value) => !value || IP_ADDRESS_REGEX.test(value.trim()) || CIDR_NOTATION_REGEX.test(value.trim()),
      ),
    secondaryDnsServer: yup.string().optional(),
  })

export const addDiscoveryAppToAppGroupValidation = yup.object().shape({
  applicationGroup: yup.object().nullable().required("Application Group is Required"),
})

export const enableDiscoveryFormValidation = yup.object().shape({
  dnsServer: yup.string().required("DNS Server is Required"),
  domains: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("Domain name is required")
          .matches(DOMAIN_NAME_REGEX, "Invalid domain name")
          .test("unique", "duplicate domain", (value: any, { parent, path, createError, ...ctx }) => {
            const [, root] = (ctx as any)?.from
            const domains = root.value.domains
            const isFound = domains.filter((domain: any) => domain.name === value)
            return isFound?.length <= 1
          }),
        mode: yup.string().test("mode", "Please save this domain", (value) => {
          return value === "view"
        }),
      }),
    )
    .required("Domain are required")
    .min(1, "At least one domain is required"),
})
